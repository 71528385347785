import React from 'react'
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const IllustrationCard = class extends React.Component {
  onContextMenu(e) {
    e.preventDefault()
  }

  render() {
    const illustration = this.props.illustration

    return (
      <div className="card">
        <div className="card-image">
          <Link to={illustration.fields.slug} aria-label={illustration.frontmatter.t} onContextMenu={this.onContextMenu}>
            <GatsbyImage
              image={illustration.frontmatter.i_t.childImageSharp.gatsbyImageData}
              alt={illustration.frontmatter.t}
              className="image"
              imgClassName="illustration-image"
              width={300}
              imgStyle={{ height: "100%" }}
              placeholder="blurred"
            />
          </Link>
        </div>
        <div className="card-content">
          <p className="card-title is-size-6-desktop mb-2">{illustration.frontmatter.t}</p>
          <div className="tags are-normal">
            {illustration.frontmatter.tags && illustration.frontmatter.tags.map((tag, index) => (
              <Link to={`/illustrations/?tag=${tag}`} className="tag is-success is-light" key={index}>#{tag}</Link>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default IllustrationCard
