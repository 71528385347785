import { useStaticQuery, graphql } from "gatsby"

export const useLatestIllustrations = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query LatestIllustrations {
        allMarkdownRemark(
          filter: {frontmatter: {templateKey: {eq: "illustration"}}}
          sort: {order: DESC, fields: frontmatter___d}
          limit: 4
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                id
                t
                d(formatString: "YYYY-MM-DD")
                tags
                i_t {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 50,
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allMarkdownRemark
}
