import React from 'react'
import { useSiteMetadata } from "../../hooks/use-site-metadata"

const Information = () => {
  const { siteMetadata } = useSiteMetadata()
  return (
    <section id="information" className="hero mb-6 mt-5">
      <div className="columns is-mobile is-centered">
        <div className="column is-11-mobile is-8-tablet">
          <article className="message is-info">
            <div className="message-header">
              <p>依頼受付について</p>
            </div>
            <div className="message-body">
              {siteMetadata.acceptRequestDesc.map((desc, index) => (
                <p key={index}>{desc}</p>
              ))}
            </div>
          </article>
        </div>
      </div>
    </section>
  )
}

export default Information
