import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { useSiteMetadata } from "../../hooks/use-site-metadata"

const About = () => {
  const { siteMetadata } = useSiteMetadata()
  return (
    <section id="about" className="hero is-small mb-6">
      <h2 className="title has-text-centered">About</h2>
      <div className="hero-body px-1 pt-0">
        <div className="columns is-multiline">
          <div className="column is-6">
            <h1 className="is-size-3 has-text-centered my-3">るるふ (lugh)</h1>
            <div className="columns is-mobile is-centered mx-auto sns">
              <div className="column is-3-mobile is-3-tablet is-2-widescreen">
                <a href="https://twitter.com/olugho" target="_blank" rel="nofollow noopener noreferrer">
                  <StaticImage src="../../images/twitter.png" alt="twitter" />
                </a>
              </div>
              <div className="column is-3-mobile is-3-tablet is-2-widescreen">
                <a href="https://www.pixiv.net/users/2485121" target="_blank" rel="nofollow noopener noreferrer">
                  <StaticImage src="../../images/pixiv.png" alt="pixiv" />
                </a>
              </div>
            </div>
            <div className="column is-10 is-offset-1">
              <p>{siteMetadata.aboutDescription}</p>
              <p className="mt-3">
                <span>欲しい物リスト: </span>
                <span>
                  <a href={siteMetadata.aboutAmazonWishListUrl} target="_blank" rel="nofollow noopener noreferrer">amazon.jp/hz/wishlist/ls…</a>
                </span>
              </p>
            </div>
          </div>
          <div className="column is-6">
            <StaticImage src="../../images/about.jpg" alt="about" className="image mx-auto image-top-about" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
