import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const Request = () => {
  return (
    <section id="request" className="hero is-small mb-6">
      <h2 className="title has-text-centered">Request</h2>
      <h3 className="is-size-5 has-text-centered">ご依頼、ご相談はこちらから</h3>
      <div className="hero-body px-1">
        <div className="columns is-mobile is-variable is-8-tablet is-3-mobile is-multiline is-centered">
          <div className="column is-6-mobile is-3-tablet">
            <div className="request-image mx-auto mb-2">
              <a href="https://skima.jp/profile?id=140182" target="_blank" rel="nofollow noopener noreferrer">
                <StaticImage src="../../images/skima.png" alt="skima" />
              </a>
            </div>
            <p className="has-text-centered">
              <a href="https://skima.jp/profile?id=140182" target="_blank" rel="nofollow noopener noreferrer" className="has-text-centered">個人の方はこちら</a>
            </p>
          </div>
          <div className="column is-6-mobile is-3-tablet">
            <div className="request-image mx-auto mb-2">
              <a href="https://twitter.com/messages/compose?recipient_id=4468642693" target="_blank" rel="nofollow noopener noreferrer">
                <StaticImage src="../../images/twitter.png" alt="skima" />
              </a>
            </div>
            <p className="has-text-centered">
              <a href="https://twitter.com/messages/compose?recipient_id=4468642693" target="_blank" rel="nofollow noopener noreferrer">企業の方はこちら</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Request
