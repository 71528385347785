import React from 'react'

import Layout from '../components/layout'
import BaseSEO from '../components/seo'
import Information from '../components/top/information'
import Illustration from '../components/top/illustration'
import About from '../components/top/about'
import Request from '../components/top/request'

const IngexPage = ({ location }) => (
  <Layout isRoot={true} isWebsite={true} noIndex={false} location={location}>
    <BaseSEO title="るるふのしごと部屋" description="るるふのイラストをまとめています。有償依頼も受け付けています。" />
    <Information />
    <Illustration />
    <About />
    <Request />
  </Layout>
)

export default IngexPage
