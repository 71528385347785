import React from 'react'
import { Link } from "gatsby"
import IllustrationCard from '../illustrations/card'
import { useLatestIllustrations } from "../../hooks/use-latest-illustrations"

const Illustration = () => {
  const { edges: illustrations } = useLatestIllustrations()

  return (
    <section id="illustrations" className="hero is-small mb-6">
      <h2 className="title has-text-centered">Illustrations</h2>
      <div className="hero-body px-1">
        <div className="columns is-mobile is-multiline is-centered is-variable is-2">
          {illustrations && illustrations.map( ({ node: illustration }) => (
            <div className="column is-6-mobile is-3-tablet" key={illustration.id}>
              <IllustrationCard illustration={illustration}/>
            </div>
          ))}
        </div>
      </div>
      <div className="hero-foot has-text-right">
        <Link to="/illustrations" className="button is-link is-light is-size-6 is-size-7-mobile">もっと見る &gt;</Link>
      </div>
    </section>
  )
}

export default Illustration
